/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from "react";
import { Row, Col } from "react-bootstrap";

import LogoIcon from "../../../../../assets/brand/logo-icon.svg";
import DuplicateIcon from "../../../../../assets/images/icons/duplicate-icon.svg";
import RemoveIcon from "../../../../../assets/images/icons/trash-icon.svg";

import Select, { components } from "react-select";
const InputOption = ({
  getStyles,
  Icon,
  isDisabled,
  isFocused,
  isSelected,
  children,
  innerProps,
  ...rest
}) => {
  const [isActive, setIsActive] = useState(false);
  const onMouseDown = () => setIsActive(true);
  const onMouseUp = () => setIsActive(false);
  const onMouseLeave = () => setIsActive(false);

  // styles
  let bg = "transparent";
  if (isFocused) bg = "#eee";
  if (isActive) bg = "#B2D4FF";

  const style = {
    alignItems: "center",
    backgroundColor: bg,
    color: "inherit",
    display: "flex ",
  };

  // prop assignment
  const props = {
    ...innerProps,
    onMouseDown,
    onMouseUp,
    onMouseLeave,
    style,
  };

  return (
    <components.Option
      {...rest}
      isDisabled={isDisabled}
      isFocused={isFocused}
      isSelected={isSelected}
      getStyles={getStyles}
      innerProps={props}
    >
      <input type="checkbox" defaultChecked={isSelected} />
      {children}
    </components.Option>
  );
};
function AddRulesApplyto(props) {
  const [ruleDataLoading, setRuleDataLoading] = useState(true);
  const [metaDataLoading, setMetaDataLoading] = useState(true);
  const [selectedOptions, setSelectedOptions] = useState();
  const [newSelectedOptions, setNewSelectedOptions] = useState([]);

  const [metaData, setMetaData] = useState([]);
  const [rulesMetaData, setRulesMetaData] = useState({});
  const [actionCriteriaDetails, setActionCriteriaDetails] = useState([]);
  const [actionUpToCriteriaDetails, setActionUpToCriteriaDetails] = useState(
    []
  );
  const [applyTo, setApplyTo] = useState([]);
  const [marketPlaces, setMarketPlaces] = useState([]);
  const [marketPlacesDropdown, setMarketPlacesDropdown] = useState([]);
  const [matchTypes, setMatchTypes] = useState([]);
  const [matchTypesSelected, setMatchTypesSelected] = useState({
    autoCampaign: [],
    manualCampaign: { keywordMatch: [], productMatch: [] },
  });
  const [reportDuriationDetails, setReportDuriationDetails] = useState([]);
  const [targetCriteriaDetails, setTargetCriteriaDetails] = useState([]);
  const [pageData, setPageData] = useState([]);

  const [formValues, setFormValues] = useState([
    { conditionFilter: "", conditionName: "", conditionValue: "" },
  ]);

  useEffect(() => {
    if (props.rulesMetaData) {
      let applyToDetails = props.rulesMetaData.applyToDetails;
      if (applyToDetails !== undefined) {
        setRulesMetaData(props.rulesMetaData);
        setActionCriteriaDetails(props.rulesMetaData.actionCriteriaDetails);
        setActionUpToCriteriaDetails(
          props.rulesMetaData.actionUpToCriteriaDetails
        );
        setApplyTo(applyToDetails.applyTo);
        setMarketPlaces(applyToDetails.marketPlaces);
        let marketplaceList = applyToDetails.marketPlaces;
        let newMarketplaceList = [];
        if (marketplaceList.length > 0) {
          for (let i = 0; i < marketplaceList.length; i++) {
            let obj = {
              value: marketplaceList[i].country,
              label: marketplaceList[i].country,
            };
            newMarketplaceList.push(obj);
          }
        }
        setMarketPlacesDropdown(newMarketplaceList);
        setMatchTypes(applyToDetails.matchTypes);
        setReportDuriationDetails(props.rulesMetaData.reportDurationDetails);
        setTargetCriteriaDetails(props.rulesMetaData.targetCriteriaDetails);
        setRuleDataLoading(false);
      }
    }
    setMetaData(props.metaData);
  }, [props.rulesMetaData, rulesMetaData, props.metaData, metaData]);

  useEffect(() => {
    if (props.metaData && props.rulesMetaData.applyToDetails) {
      if (props.metaData.text) {
        buildApplyToDropdownData(
          props.rulesMetaData.applyToDetails.applyTo,
          props.metaData
        );
        setMetaDataLoading(false);
      }
    }
  }, [props.rulesMetaData, props.metaData]);

  useEffect(() => {
    setPageData(props.wizardData.applyToWizard);
    console.log("Applyto page Data: ", props.wizardData.applyToWizard);
    if (props.wizardData.applyToWizard.marketPlace) {
      let selected = [];
      let applyToOptionData = props.wizardData.applyToWizard.marketPlace;
      for (let i = 0; i < applyToOptionData.length; i++) {
        let obj = { value: applyToOptionData[i], label: applyToOptionData[i] };
        selected.push(obj);
      }
      setSelectedOptions(selected);
    }
    if (props.wizardData.applyToWizard.applyTo.length > 0) {
      let applyToList = [];
      let applyToData = props.wizardData.applyToWizard.applyTo;
      for (let i = 0; i < applyToData.length; i++) {
        if (applyToData[i].expressions.operand) {
          let obj = {
            conditionFilter: applyToData[i].expressions.operand,
            conditionName: applyToData[i].expressions.operator,
            conditionValue:
              applyToData[i].expressions.value[0].inputExpression.input,
          };
          applyToList.push(obj);
        }
      }
      setFormValues(applyToList);
      if (applyToList.length < 1) {
        setFormValues([
          ...formValues,
          { conditionFilter: "", conditionName: "", conditionValue: "" },
        ]);
      }
    } else {
      if (formValues.length < 1) {
        setFormValues([
          ...formValues,
          { conditionFilter: "", conditionName: "", conditionValue: "" },
        ]);
      }
    }
  }, []);

  /* Apply to wizard */
  const handleSelect = (data) => {
    console.log("Selected market places", data);
    setSelectedOptions(data || []); // Update state immediately
    wizardDataUpdate("marketplace", data);
  };

  let matchTypesSelect = (matchType, e) => {
    let newMatchTypesSelected = matchTypesSelected;
    if (matchType === "autoCampaign") {
      if (e.target.checked) {
        newMatchTypesSelected["autoCampaign"].push(e.target.value);
      } else {
        let newArray = newMatchTypesSelected["autoCampaign"].filter(
          (item) => item !== e.target.value
        );
        newMatchTypesSelected["autoCampaign"] = newArray;
      }
    }
    if (matchType === "keywordMatch") {
      if (e.target.checked) {
        newMatchTypesSelected["manualCampaign"]["keywordMatch"].push(
          e.target.value
        );
      } else {
        let newArray = newMatchTypesSelected["manualCampaign"][
          "keywordMatch"
        ].filter((item) => item !== e.target.value);
        newMatchTypesSelected["manualCampaign"]["keywordMatch"] = newArray;
      }
    }
    if (matchType === "productMatch") {
      if (e.target.checked) {
        newMatchTypesSelected["manualCampaign"]["productMatch"].push(
          e.target.value
        );
      } else {
        let newArray = newMatchTypesSelected["manualCampaign"][
          "productMatch"
        ].filter((item) => item !== e.target.value);
        newMatchTypesSelected["manualCampaign"]["productMatch"] = newArray;
      }
    }
    setMatchTypesSelected(newMatchTypesSelected);
    wizardDataUpdate();
  };
  function wizardDataUpdate(type, data) {
    let applyTo = [];
    for (let i = 0; i < formValues.length; i++) {
      let obj = {
        logicalAND: true,
        expressions: {
          operand: formValues[i].conditionFilter,
          operator: formValues[i].conditionName,
          value: [
            {
              inputType: "List",
              inputExpression: {
                operand: null,
                input: formValues[i].conditionValue,
              },
            },
          ],
          unit: null,
        },
      };
      applyTo.push(obj);
    }

    let marketPlace = newSelectedOptions;
    if (type === "marketplace") {
      for (let j = 0; j < data.length; j++) {
        if (marketPlace.indexOf(data[j].value) === -1) {
          marketPlace.push(data[j].value);
        }
      }
      setNewSelectedOptions(marketPlace);
    }
    let matchType = matchTypesSelected;
    let formData = {
      type: "applyto",
      marketPlace: marketPlace,
      applyTo: applyTo,
      matchType: matchType,
    };
    props.parentCallback(formData);
    props.wizardDataCallback(formData);
  }

  let handleChange = (i, e) => {
    let newFormValues = [...formValues];
    newFormValues[i][e.target.name] = e.target.value;
    setFormValues(newFormValues);
    wizardDataUpdate();
  };

  let addFormFields = () => {
    setFormValues([
      ...formValues,
      { conditionFilter: "", conditionName: "", conditionValue: "" },
    ]);
  };
  let duplicateFormFields = (i) => {
    let newFormValues = [...formValues];
    let duplicateRow = {
      conditionFilter: newFormValues[i].conditionFilter,
      conditionName: newFormValues[i].conditionName,
      conditionValue: newFormValues[i].conditionValue,
    };
    newFormValues.push(duplicateRow);
    setFormValues(newFormValues);
    wizardDataUpdate();
  };
  let removeFormFields = (i) => {
    let newFormValues = [...formValues];
    newFormValues.splice(i, 1);
    setFormValues(newFormValues);
    wizardDataUpdate();
  };

  let handleSubmit = (event) => {};

  function buildApplyToDropdownData(listData, metaDataList) {
    let result = metaDataList;
    if (listData && result) {
      for (let i = 0; i < listData.length; i++) {
        listData[i]["id"] = i + 1;
        listData[i]["selected"] = false;
        let slectedConditionType = listData[i]["filterConditionType"];
        let conditionTypeList = result[slectedConditionType];
        listData[i]["conditionTypes"] = conditionTypeList;
      }
      setApplyTo(listData);
    }
  }
  const SubSelectOptions = (props) => {
    let { index, eleData } = props;
    if (eleData.conditionFilter) {
      let findIndex = applyTo.findIndex(
        (obj) => obj.name == eleData.conditionFilter
      );
      let conditionData = applyTo[findIndex];
      let optionData = conditionData.conditionTypes;
      if (optionData.length > 0) {
        return optionData.map((options, i) => (
          <option key={i} value={options.value}>
            {options.name}
          </option>
        ));
      } else {
        return false;
      }
    } else {
      return false;
    }
  };
  return (
    <>
      <div className="wizard-step">
        {!metaDataLoading && !ruleDataLoading ? (
          <>
            <div className="card">
              <Row>
                <Col md={12}>
                  <h4 className="title">Marketplace</h4>
                </Col>
                <Col md={4}>
                  <form>
                    <div>
                      <Select
                        key={selectedOptions.length} // Change key when the selected options change
                        isMulti
                        closeMenuOnSelect={false}
                        hideSelectedOptions={false}
                        onChange={handleSelect}
                        options={marketPlacesDropdown}
                        components={{
                          Option: InputOption,
                        }}
                        value={selectedOptions}
                      />
                    </div>
                  </form>
                </Col>
              </Row>
            </div>
            <div className="card">
              <Row>
                <Col md={12}>
                  <h4 className="title">Apply to</h4>
                </Col>
              </Row>

              <Row>
                <Col md={12}>
                  <form>
                    {formValues.map((element, index) => (
                      <div className="form-row mb-3" key={index}>
                        <div className="form-field-group">
                          <div className="form-field">
                            <select
                              className="form-select"
                              name="conditionFilter"
                              value={element.conditionFilter || ""}
                              onChange={(e) => {
                                handleChange(index, e);
                              }}
                            >
                              <option value="">--Select--</option>
                              {applyTo.map((list, i) => (
                                <option value={list.name}>
                                  {list.displayName}
                                </option>
                              ))}
                            </select>
                          </div>
                          <div className="form-field">
                            <select
                              className="form-select"
                              name="conditionName"
                              value={element.conditionName || ""}
                              onChange={(e) => handleChange(index, e)}
                            >
                              <option value="">--Select--</option>
                              <SubSelectOptions
                                index={index}
                                eleData={element}
                              />
                            </select>
                          </div>
                          <div className="form-field">
                            <input
                              type="text"
                              placeholder="Enter Keyword"
                              name="conditionValue"
                              className="form-control"
                              value={element.conditionValue || ""}
                              onChange={(e) => handleChange(index, e)}
                            />
                          </div>
                        </div>
                        <div className="form-action a-2">
                          <div
                            className="icon-box-white cursor"
                            onClick={() => duplicateFormFields(index)}
                          >
                            <img src={DuplicateIcon} alt="" />
                          </div>
                          {index !== 0 && (
                            <div
                              className="icon-box-gray cursor"
                              onClick={() => removeFormFields(index)}
                            >
                              <img src={RemoveIcon} alt="" />
                            </div>
                          )}
                        </div>
                      </div>
                    ))}
                  </form>
                  <div>
                    <button
                      type="button"
                      className="btn btn-link add-more-btn"
                      onClick={() => addFormFields()}
                    >
                      <i className="circle-add-icon"></i> Add More
                    </button>
                  </div>
                </Col>
              </Row>
            </div>
            {!ruleDataLoading && (
              <div className="card">
                <form>
                  <Row>
                    <Col md={12}>
                      <h4 className="title">Match Types</h4>
                    </Col>

                    <Col md={6}>
                      {matchTypes.autoCampaign.length > 0 && (
                        <div className="gray-box">
                          <div className="gray-box-header">
                            <div>
                              <h4 className="title">Auto Campaign:</h4>
                            </div>
                            <div className="text-end">
                              <button type="button" className="btn btn-link">
                                Select All
                              </button>
                            </div>
                          </div>
                          <div className="option-list-container mt-2">
                            {matchTypes.autoCampaign.map((list, i) => {
                              let findIndex = -1;
                              if (pageData.matchType.autoCampaign) {
                                findIndex =
                                  pageData.matchType.autoCampaign.findIndex(
                                    (ele) => ele == list
                                  );
                              }
                              if (findIndex > -1) {
                                return (
                                  <div className="form-check">
                                    <input
                                      className="form-check-input"
                                      type="checkbox"
                                      value={list}
                                      id={"autoCampain" + i}
                                      onChange={(e) =>
                                        matchTypesSelect("autoCampaign", e)
                                      }
                                      defaultChecked={true}
                                    />
                                    <label
                                      className="form-check-label"
                                      htmlFor={"autoCampain" + i}
                                    >
                                      {list}
                                    </label>
                                  </div>
                                );
                              } else {
                                return (
                                  <div className="form-check">
                                    <input
                                      className="form-check-input"
                                      type="checkbox"
                                      value={list}
                                      id={"autoCampain" + i}
                                      onChange={(e) =>
                                        matchTypesSelect("autoCampaign", e)
                                      }
                                    />
                                    <label
                                      className="form-check-label"
                                      htmlFor={"autoCampain" + i}
                                    >
                                      {list}
                                    </label>
                                  </div>
                                );
                              }
                            })}
                          </div>
                        </div>
                      )}
                    </Col>
                    <Col md={6}>
                      <div className="gray-box">
                        <div className="gray-box-header">
                          <div>
                            <h4 className="title">Manual Campaign:</h4>
                          </div>
                          <div className="text-end">
                            <button type="button" className="btn btn-link">
                              Select All
                            </button>
                          </div>
                        </div>

                        <Row>
                          {matchTypes.manualCampaign.keywordMatch.length >
                            0 && (
                            <Col>
                              <div className="option-list-container mt-2">
                                <h6>Keyword Targeting</h6>
                                {matchTypes.manualCampaign.keywordMatch.map(
                                  (list, i) => {
                                    let findIndex = -1;
                                    if (
                                      pageData.matchType.manualCampaign &&
                                      pageData.matchType.manualCampaign
                                        .keywordMatch
                                    ) {
                                      findIndex =
                                        pageData.matchType.manualCampaign.keywordMatch.findIndex(
                                          (ele) => ele == list
                                        );
                                    }
                                    if (findIndex > -1) {
                                      return (
                                        <div className="form-check">
                                          <input
                                            className="form-check-input"
                                            type="checkbox"
                                            value={list}
                                            id={"keywordMath" + i}
                                            onChange={(e) =>
                                              matchTypesSelect(
                                                "keywordMatch",
                                                e
                                              )
                                            }
                                            defaultChecked={true}
                                          />
                                          <label
                                            className="form-check-label"
                                            htmlFor={"keywordMath" + i}
                                          >
                                            {list}
                                          </label>
                                        </div>
                                      );
                                    } else {
                                      return (
                                        <div className="form-check">
                                          <input
                                            className="form-check-input"
                                            type="checkbox"
                                            value={list}
                                            id={"keywordMath" + i}
                                            onChange={(e) =>
                                              matchTypesSelect(
                                                "keywordMatch",
                                                e
                                              )
                                            }
                                          />
                                          <label
                                            className="form-check-label"
                                            htmlFor={"keywordMath" + i}
                                          >
                                            {list}
                                          </label>
                                        </div>
                                      );
                                    }
                                  }
                                )}
                              </div>
                            </Col>
                          )}
                          {matchTypes.manualCampaign.productMatch.length >
                            0 && (
                            <Col>
                              <div className="option-list-container mt-2">
                                <h6>Product Targeting</h6>
                                {matchTypes.manualCampaign.productMatch.map(
                                  (list, i) => {
                                    let findIndex = -1;
                                    if (
                                      pageData.matchType.manualCampaign &&
                                      pageData.matchType.manualCampaign
                                        .productMatch
                                    ) {
                                      findIndex =
                                        pageData.matchType.manualCampaign.productMatch.findIndex(
                                          (ele) => ele == list
                                        );
                                    }
                                    if (findIndex > -1) {
                                      return (
                                        <div className="form-check">
                                          <input
                                            className="form-check-input"
                                            type="checkbox"
                                            value={list}
                                            id={"productMath" + i}
                                            onChange={(e) =>
                                              matchTypesSelect(
                                                "productMatch",
                                                e
                                              )
                                            }
                                            defaultChecked={true}
                                          />
                                          <label
                                            className="form-check-label"
                                            htmlFor={"productMath" + i}
                                          >
                                            {list}
                                          </label>
                                        </div>
                                      );
                                    } else {
                                      return (
                                        <div className="form-check">
                                          <input
                                            className="form-check-input"
                                            type="checkbox"
                                            value={list}
                                            id={"productMath" + i}
                                            onChange={(e) =>
                                              matchTypesSelect(
                                                "productMatch",
                                                e
                                              )
                                            }
                                          />
                                          <label
                                            className="form-check-label"
                                            htmlFor={"productMath" + i}
                                          >
                                            {list}
                                          </label>
                                        </div>
                                      );
                                    }
                                  }
                                )}
                              </div>
                            </Col>
                          )}
                        </Row>
                      </div>
                    </Col>
                  </Row>
                </form>
              </div>
            )}
          </>
        ) : (
          <div className="loading-container">
            <div className="loading-text">
              <span className="logicon">
                <img src={LogoIcon} alt="logo-small"></img>
              </span>
              <span>L</span>
              <span>O</span>
              <span>A</span>
              <span>D</span>
              <span>I</span>
              <span>N</span>
              <span>G</span>
            </div>
          </div>
        )}
      </div>
    </>
  );
}

export default AddRulesApplyto;
