import React, { Component } from "react";
import DashboardLayout from "../../layouts/DashboardLayout";
import { Row, Col } from "react-bootstrap";
import "./index.css";

import LinkIcon from "../../assets/images/icons/link-icon.svg";

import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";

import { DateRangePicker } from "rsuite";
import isAfter from "date-fns/isAfter";
import Moment from "moment";

import MarketplaceTable from "./table/marketplace-table";
import AdGroupTable from "./table/adgroup-table";
import CampaignTable from "./table/campaign-table";
import PortfoliosTable from "./table/portfolio-table";
import ProductsTable from "./table/products-table";
import TargetingTable from "./table/targeting-table";
import SearchTermsTable from "./table/searchterms-table";

class AdsCompaignManager extends Component {
  filterData: any = [];
  current = new Date();
  startDate: any = new Date().setDate(this.current.getDate() - 30);
  endDate = new Date();

  state = {
    globalFilterFromData: [],
    searchKeyFilter: "",
    globalFilterDateRange: [this.startDate, this.endDate],
    metaData: [],
    lastUpdatedDate: localStorage.getItem("lastUpdatedDate") || null,
  };
  constructor(props) {
    super(props);
    this.onChangeDateRangeSubmitGlobal =
      this.onChangeDateRangeSubmitGlobal.bind(this);
  } 
  componentDidMount() {
    // this.getMetaData();
  }

  // getMetaData = async () => {
  //   let userToken = localStorage.getItem("userToken");
  //   let AuthToken = "Bearer " + userToken;
  //   let url = "https://api.aimosa.io/MasterData/meta";
  //   const response = await fetch(url, {
  //     method: "GET",
  //     headers: {
  //       "Content-Type": "application/json",
  //       Authorization: AuthToken,
  //     },
  //   });

  //   try {
  //     const responceData = await response.json();
  //     this.setState({ metaData: responceData.result.data });
  //   } catch (error) {
  //     console.error("Error fetching data:", error);
  //   }
  // };

  handleNvEnter = (event) => {
    console.log("Nv Enter:", event);
  };
  handleCallback = (childData) => {
    this.setState({ globalFilterFromData: childData });
    this.filterData = childData;
  };
  handleKeyDown = (event: any) => {
    if (event.key === "Enter") {
      this.setState({ searchKeyFilter: event.target.value });
      event.preventDefault();
    }
  };
  cancelDropdown = () => {
    const eleRm: any = document.getElementById("dropdownCon");
    eleRm.classList.remove("open");

    const eleRm2: any = document.getElementById("dropdownCon2");
    eleRm2.classList.remove("open");
  };

  openDropdown(e) {
    const eleRm: any = document.getElementById("dropdownCon2");
    eleRm.classList.remove("open");

    const ele: any = document.getElementById("dropdownCon");
    ele.classList.add("open");
  }
  openDropdown2(e) {
    const eleRm: any = document.getElementById("dropdownCon");
    eleRm.classList.remove("open");

    const ele: any = document.getElementById("dropdownCon2");
    ele.classList.add("open");
  }

  onChangeDateRangeSubmitGlobal(e) {
    console.log(Moment(e[0]).format("YYYY-MM-DD"));
    console.log(e[1]);
    let dateRange = e;
    if (dateRange.length > 0) {
      this.setState({ globalFilterDateRange: dateRange });
    }
  }

  render() {
    return (
      <DashboardLayout>
        <div className="main-cont-header ">
          <Row className="page-header ">
            {/* <Row className="mt-2 ">
       <div className="dashboard-container padding-lr-30 campaing-manger mx-auto">
        <div className="tab-container">
          <Tabs defaultActiveKey="Sponsored Product" className="d-flex justify-content-center">
            <Tab eventKey="Sponsored Product" title="Sponsored Product">
             
            </Tab>
            <Tab eventKey="Sponsored Display" title="Sponsored Display">
            
            </Tab>
            <Tab eventKey="Sponsored Brand" title="Sponsored Brand">
              
            </Tab>
          </Tabs>
        </div>
      </div>
     </Row> */}

            <Col>
              <div className="main-con-page-title-container">
                <div className="title">
                  <h3 className="page-title">Campaign Manager</h3>
                </div>
             
                {/* <div className="accounts me-2">
                  <select
                    className="form-select"
                    name="TypeSelect"
                    id="TypeSelect"
                  >
                    <option>Account-1</option>
                    <option>Account-2</option>
                    <option>Account-3</option>
                  </select>
                </div> */}
                <div className="filters">
                  <DateRangePicker
                    placeholder="Select Date Range"
                    onChange={this.onChangeDateRangeSubmitGlobal}
                    format="yyyy-MM-dd"
                    disabledDate={(date) => isAfter(date, new Date())}
                    // defaultValue={[this.startDate, new Date()]}
                  />
                </div>
                <div className="accounts ms-2">
                  <select
                    className="form-select"
                    name="accountSelect"
                    id="accountSelect"
                  >
                    <option>Sponsored Product</option>
                    <option>Sponsored Display</option>
                    <option>Sponsored Brand</option>
                  </select>
                </div>
              </div>
            </Col>
            {/* <Col className="text-end last-sync">
              <span>
             
                Last App Sync :
              </span>
              <span className="time-summery">
                {this.state.lastUpdatedDate || "Not available"}
              </span>
            </Col> */}
          </Row>
        </div>
        <div className="main-content-container mt-4">
          <hr />
          <div className="dashboard-container padding-lr-30 campaing-manger">
            <div className="tab-container">
              <Tabs defaultActiveKey="marketplace">
                <Tab eventKey="marketplace" title="Marketplace">
                  <MarketplaceTable
                    checkBox={false}
                    tabName="Marketplaces"
                    filterData={this.state.globalFilterFromData}
                    searchKey={this.state.searchKeyFilter}
                    filterDateRange={this.state.globalFilterDateRange}
                    metaData={this.state.metaData}
                  />
                </Tab>
                <Tab eventKey="portfolios" title="Portfolios">
                  <PortfoliosTable
                    checkBox={false}
                    tabName="Portfolios"
                    filterData={this.state.globalFilterFromData}
                    searchKey={this.state.searchKeyFilter}
                    filterDateRange={this.state.globalFilterDateRange}
                    metaData={this.state.metaData}
                  />
                </Tab>
                <Tab eventKey="campaign" title="Campaign">
                  <CampaignTable
                    checkBox={true}
                    tabName="Campaigns"
                    filterData={this.state.globalFilterFromData}
                    searchKey={this.state.searchKeyFilter}
                    filterDateRange={this.state.globalFilterDateRange}
                    metaData={this.state.metaData}
                  />
                </Tab>
                <Tab eventKey="adgroup" title="Ad Group">
                  <AdGroupTable
                    checkBox={true}
                    tabName="AdGroups"
                    filterData={this.state.globalFilterFromData}
                    searchKey={this.state.searchKeyFilter}
                    filterDateRange={this.state.globalFilterDateRange}
                    metaData={this.state.metaData}
                  />
                </Tab>
                <Tab eventKey="targeting" title="Targeting">
                  <TargetingTable
                    checkBox={true}
                    tabName="Targets"
                    filterData={this.state.globalFilterFromData}
                    searchKey={this.state.searchKeyFilter}
                    filterDateRange={this.state.globalFilterDateRange}
                    metaData={this.state.metaData}
                  />
                </Tab>
                <Tab eventKey="products" title="Products">
                  <ProductsTable
                    checkBox={true}
                    tabName="Products"
                    filterData={this.state.globalFilterFromData}
                    searchKey={this.state.searchKeyFilter}
                    filterDateRange={this.state.globalFilterDateRange}
                    metaData={this.state.metaData}
                  />
                </Tab>
                <Tab eventKey="searchterms" title="Search Terms">
                  <SearchTermsTable
                    checkBox={false}
                    tabName="SearchTerms"
                    filterData={this.state.globalFilterFromData}
                    searchKey={this.state.searchKeyFilter}
                    filterDateRange={this.state.globalFilterDateRange}
                    metaData={this.state.metaData}
                  />
                </Tab>
              </Tabs>
            </div>
          </div>
        </div>
      </DashboardLayout>
    );
  }
}

export default AdsCompaignManager;
