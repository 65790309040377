/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from 'react';
import { Row, Col } from "react-bootstrap";
import "../index.css";
import CreateRuleWizard from "../../../../components/Wizard/createRuleWizard";
import LogoIcon from "../../../../assets/brand/logo-icon.svg";

import { toast } from "react-toastify";

function AddNewRules(props) {
  const [metaData, setMetaData] = useState<any>([]);
  const [rulesMetaData, setRulesMetaData] = useState<any>({});
  const [name, setName] = useState<any>("");
  const [marketPlaces, setMarketPlaces] = useState<any>([]);
  const [applyTo, setApplyTo] = useState<any>([]);
  const [matchTypes, setMatchTypes] = useState<any>([]);
  const [targetCriteriaDetails, setTargetCriteriaDetails] = useState<any>([]);
  const [reportDuration, setReportDuration] = useState<any>("");
  const [actionCriteria, setActionCriteria] = useState<any>([]);
  const [actionUpToCriteria, setActionUpToCriteria] = useState<any>([]);
  const [schedule, setSchedule] = useState<any>({"runContinuously": true, "dateRange": null });
  const [addRulesObj, setAddRulesObj] = useState<any>({});

  const [getRuleStatus, setGetRuleStatus] = useState<any>(false);
  
  
  const [wizardData, setWizardData] = useState<any>({
    name: "",
    applyToWizard: {
      marketPlace: [],
      applyTo: [],
      matchType: [],
    },
    ifWizard: [],
    forWizard: "",
    thenWizard: [],
    untilWizard: [],
    timelineWizard: {"runContinuously": true, "dateRange": null },

  });
  useEffect(() => {
    if(props.id !== "0"){
      setGetRuleStatus(true);
      getRule();
    }
  }, []);
  

  const wizardCallback = (childData) => {
    if(childData){
      let newWizardData = wizardData; 
      if(childData.type === "applyto"){
        newWizardData["applyToWizard"]["marketPlace"] = childData.marketPlace;
        newWizardData["applyToWizard"]["applyTo"] = childData.applyTo;
        newWizardData["applyToWizard"]["matchType"] = childData.matchType;
      }
      if(childData.type === "targetCriteria"){
        newWizardData["ifWizard"] = childData.targetCriteria;
      }
      if(childData.type === "reportDuration"){
        newWizardData["forWizard"] = childData.reportDuration;
      }
      if(childData.type === "actionCriteria"){
        newWizardData["thenWizard"] = childData.actionCriteria;
      }
      if(childData.type === "actionUpToCriteria"){
        newWizardData["untilWizard"] = childData.actionUpToCriteria;
      }
      if(childData.type === "schedule"){
        newWizardData["timelineWizard"] = childData.schedule;
      }
      setWizardData(newWizardData);
      console.log(newWizardData);
    }
  }
  
  const handleCallback = (childData) => {
    console.log("Child Data: ", childData);
    if(childData.type === "applyto"){
      setMarketPlaces(childData.marketPlace);
      setApplyTo(childData.applyTo);
      setMatchTypes(childData.matchType);
    }
    if(childData.type === "targetCriteria"){
      setTargetCriteriaDetails(childData.targetCriteria);
    }
    if(childData.type === "reportDuration"){
      setReportDuration(childData.reportDuration);
    }
    if(childData.type === "actionCriteria"){
      setActionCriteria(childData.actionCriteria);
    }
    if(childData.type === "actionUpToCriteria"){
      setActionUpToCriteria(childData.actionUpToCriteria);
    }
    if(childData.type === "schedule"){
      setSchedule(childData.schedule);
    }
    if(childData.type === "submit"){
      if(props.id !== "0"){
        submitUpdateRule();
      } else {
        submitAddRule();
      }
    }
  }
  
  const submitAddRule = async () => {
    let userToken = localStorage.getItem('userToken')
    let AuthToken = "Bearer " + userToken;
    let url = "https://api.aimosa.io/Rule";
    const requestOptions = {
      method: 'POST',
      headers: { 
        'Content-Type': 'application/json',
        'Authorization': AuthToken
      },
      body: JSON.stringify({
        "name": name,
        "marketPlaces": marketPlaces,
        "applyTo": applyTo,
        "matchTypes": matchTypes,
        "targetCriteria": targetCriteriaDetails,
        "reportDuration": reportDuration,
        "actionCriteria": actionCriteria,
        "actionUpToCriteria": actionUpToCriteria,
        "schedule": schedule
      }),
    };

    try {
      const response = await fetch(url, requestOptions);
      const responceData = await response.json();
      if(responceData.success){
        let result = responceData.result;
        console.error(result);
        toast("Successfully Rule created");
      } else {
        toast("Unable to create Rule");
      }
      // setRedirect("/onboarding");
    } catch (error) {
      console.error('Error fetching data:', error);
      toast("Error On Creating Rule");
    }
  };

  const getRule = async () => {
    let userToken = localStorage.getItem('userToken')
    let AuthToken = "Bearer " + userToken;
    let url = "https://api.aimosa.io/Rule/"+props.id;
    const requestOptions = {
      method: 'GET',
      headers: { 
        'Content-Type': 'application/json',
        'Authorization': AuthToken
      }
    };

    try {
      const response = await fetch(url, requestOptions);
      const responceData = await response.json();
      if(responceData.success){
        let result = responceData.result;
        console.error("Get Rules: ", result);
        let objWizard = {
          id: result.id,
          name: result.ruleName,
          applyToWizard: {
            marketPlace: result.marketPlaces,
            applyTo: result.applyTo,
            matchType: result.matchTypes,
          },
          ifWizard: result.targetCriteria,
          forWizard: result.reportDuration,
          thenWizard: result.actionCriteria,
          untilWizard: result.actionUpToCriteria,
          timelineWizard: {"runContinuously": result.schedule.runContinuously, "dateRange": result.schedule.dateRange },
        }
        setName(result.ruleName);
        setMarketPlaces(result.marketPlaces);
        setApplyTo(result.applyTo);
        setMatchTypes(result.matchTypes);
        setTargetCriteriaDetails(result.targetCriteria);
        setReportDuration(result.reportDuration);
        setActionCriteria(result.actionCriteria);
        setActionUpToCriteria(result.actionUpToCriteria);
        setSchedule(result.schedule);

        setWizardData(objWizard);
        setGetRuleStatus(false);
      } else {
        setGetRuleStatus(false);
      }
    } catch (error) {
      console.error('Error fetching data:', error);
      toast("Error On loading Rule");
      setGetRuleStatus(false);
    }
  };
  const submitUpdateRule = async () => {
    let userToken = localStorage.getItem('userToken')
    let AuthToken = "Bearer " + userToken;
    let url = "https://api.aimosa.io/Rule";
    const requestOptions = {
      method: 'PUT',
      headers: { 
        'Content-Type': 'application/json',
        'Authorization': AuthToken
      },
      body: JSON.stringify({
        "id": props.id,
        "name": name,
        "marketPlaces": marketPlaces,
        "applyTo": applyTo,
        "matchTypes": matchTypes,
        "targetCriteria": targetCriteriaDetails,
        "reportDuration": reportDuration,
        "actionCriteria": actionCriteria,
        "actionUpToCriteria": actionUpToCriteria,
        "schedule": schedule
      }),
    };

    try {
      const response = await fetch(url, requestOptions);
      const responceData = await response.json();
      if(responceData.success){
        let result = responceData.result;
        console.error(result);
        toast("Successfully Rule updated");
      } else {
        toast("Unable to create Rule");
      }
      // setRedirect("/onboarding");
    } catch (error) {
      console.error('Error fetching data:', error);
      toast("Error On Creating Rule");
    }
  };

  let handleChange = (e) => {
    let newFormValues = addRulesObj;
    newFormValues["name"] = e.target.value;
    setName(e.target.value);
    let newWizardDataName = wizardData;
    newWizardDataName['name'] = e.target.value;
    setWizardData(newWizardDataName);
    console.log("Wizard Data: ",newWizardDataName);
  }

  useEffect(() => {
    if(rulesMetaData){
      if(!rulesMetaData.applyToDetails){
        getRulesMetaData();
      }
    }
    if(metaData){
      if(metaData.length < 1){
        getMetaData();
      }
    }
  }, [metaData, rulesMetaData]);

  const getMetaData = async () => {
    let userToken = localStorage.getItem('userToken')
    let AuthToken = "Bearer " + userToken;
    let url = "https://api.aimosa.io/MasterData/meta";
    const response = await fetch(
      url,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: AuthToken,
        },
      }
    );

    try {
      const responceData = await response.json();
      setMetaData(responceData.result.data);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  const getRulesMetaData = async () => {
    let userToken = localStorage.getItem('userToken')
    let AuthToken = "Bearer " + userToken;
    let url = "https://api.aimosa.io/MasterData/Rules_Creation";
    const response = await fetch(
      url,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: AuthToken,
        },
      }
    );

    try {
      const responceData = await response.json();
      setRulesMetaData(responceData.result.data);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  return (
    <>
      <div className="add-rule-container add-new-rule">
        <Row>
          <Col>
            <h4>Add New Rule</h4>
          </Col>
        </Row>
        {!getRuleStatus?
        <Row className="mt-3">
          <h5>Rule Name</h5>
          <Col md={12}>
            <Row>
              <Col md={3}>
                <div className="mb-3">
                  <form>
                    <div>
                      <input className="form-control" type="text" placeholder="enter rule name or rule set" id="globalSearch" name="globalSearch" onChange={e => handleChange(e)} defaultValue={name}/>
                    </div>
                  </form>
                </div>
              </Col>
            </Row>
          </Col>
          <Row>
            <Col className="steperDesign">
              { rulesMetaData && 
              <CreateRuleWizard rulesMetaData={rulesMetaData} metaData={metaData} parentCallback={handleCallback} wizardData={wizardData} wizardCallback={wizardCallback} />}
            </Col>
          </Row>
        </Row> : 
        <div className="loading-container">
        <div className="loading-text">
          <span className='logicon'><img src={LogoIcon} alt='logo-small'></img></span>
          <span>L</span>
          <span>O</span>
          <span>A</span>
          <span>D</span>
          <span>I</span>
          <span>N</span>
          <span>G</span>
        </div>
      </div>
        }
      </div>
    </>
  );
}

export default AddNewRules;