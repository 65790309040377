import React, { useState } from "react";
import "./index.css";
import { Link } from "react-router-dom";

import Logo from "../../assets/brand/logo-icon-white.svg";
import LogoL from "../../assets/brand/logo-white.svg";
import menuItems from "./menu.json";
import menuItemsBottom from "./menu-bottom.json";
import sideHrLine from "../../assets/images/side-menu-hr-line.svg";
import { useLocation } from "react-router-dom";
import { elements } from "chart.js";

const Sidebar = () => {
  const location = useLocation();
  const { pathname } = location;
  const splitLocation = pathname.split("/");

  // document.addEventListener('click', function(event:any){
  //   const yourContainer:any = document.querySelector('.sidebar');
  //   if(event.target){
  //     if(!yourContainer.contains(event.target)) {
  //       const ele:any = document.getElementById('sideMenubar');
  //       if(ele.length > 0){
  //         ele.classList.remove('open');
  //       }
  //     }
  //   }
  // });

  function collapseMenu(e) {
    const ele: any = document.getElementById("sideMenubar");
    //ele.classList.add('open');
  }

  function collapseSubmenu(e) {
    if (e.target.closest(".has-submenu")) {
      let mainParent = e.target.closest(".sidebar");
      let allSubmenuContainer =
        mainParent.getElementsByClassName("submenu-dropdown");
      for (let i = 0; allSubmenuContainer.length > i; i++) {
        allSubmenuContainer[i].classList.remove("open");
      }

      console.log("has submenu");
      let ele = e.target.closest(".has-submenu");
      let submenuContainer = ele.getElementsByClassName("submenu-dropdown");
      for (let i = 0; allSubmenuContainer.length > i; i++) {
        submenuContainer[i].classList.add("open");
      }
    }
    console.log(e);
  }

  return (
    <div id="sideMenubar" className="sidebar" onClick={collapseMenu}>
      <div className="header-wrapper">
        <div className="brand-logo">
          {/* <img src={Logo} alt="logo" className="logosmall" />
          <img src={LogoL} alt="logo" className="logolarge" /> */}
          <h5 className="mt-3 ps-3" style={{color:"white"}}>Get Growth Digital</h5>
        </div>
      
      </div>
      <div>
        <nav>
          <ul className="menus">
            {menuItems.map((menu, index) => {
              const submenuList: any = menu.submenuList;
              return (
                <li
                  className={
                    "menu-items" +
                    (splitLocation[1] === menu.active ? " active" : "") +
                    (menu.hasSubMenu ? " has-submenu" : "")
                  }
                  key={index}
                  onClick={collapseSubmenu}
                >
                  <Link to={menu.to}>
                    <i className={menu.icon}></i>
                    <span>{menu.name}</span>
                  </Link>
                  {menu.hasSubMenu && (
                    <ul
                      className={
                        "submenu-dropdown" +
                        (splitLocation[1] === menu.active ? " open" : "")
                      }
                    >
                      {submenuList.map((submenu, index) => {
                        return (
                          <li
                            className={
                              "menu-items" +
                              (splitLocation[2] === submenu.active
                                ? " active"
                                : "")
                            }
                            key={index}
                          >
                            <Link to={submenu.to}>
                              <span>{submenu.name}</span>
                            </Link>
                          </li>
                        );
                      })}
                    </ul>
                  )}
                </li>
              );
            })}
          </ul>

          <ul className="menus bottom">
            <div className="hr-line-conainer">
              <img src={sideHrLine} alt="hr line" />
            </div>
            {menuItemsBottom.map((menu, index) => {
              return (
                <li
                  className={"menu-items" + (menu.active ? " active" : "")}
                  key={index}
                >
                  <Link to={menu.to}>
                    <i className={menu.icon}></i>
                    <span>{menu.name}</span>
                  </Link>
                </li>
              );
            })}
          </ul>
        </nav>
      </div>
    </div>
  );
};

export default Sidebar;
