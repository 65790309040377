import React, { Component } from "react";
import "./index.css";
import OnOffIcon from "../../assets/images/icons/on-off-icon.svg";
import BellIcon from "../../assets/images/icons/bell-icon.svg";
import GroupMemberIcon from "../../assets/images/icons/group-member-icon.svg";
import ProfileIcon from "../../assets/images/icons/profile-icon.svg";
import ActiveIcon from "../../assets/images/icons/acive-green-icon.svg";
import ProfileDropIcon from "../../assets/images/icons/profile-down-arrow.svg";
import upgradetoproImage from "../../assets/images/icons/upgradetopro.jpg";
import addaccount from "../../assets/images/icons/addaccount.png";
import logouticon from "../../assets/images/icons/download (1).png";
import { Nav, Navbar, Container, NavDropdown } from "react-bootstrap";

class Header extends Component {
  state = {
    userNames: localStorage.getItem("userName"),
    userImage: localStorage.getItem("userimg"),
  };

  componentDidMount() {
    // this.getProfile();
  }

  // getProfile = async () => {
  //   let userToken = localStorage.getItem("userToken");
  //   let AuthToken = "Bearer " + userToken;

  //   try {
  //     const response = await fetch("https://api.aimosa.io/Users/Me", {
  //       method: "GET",
  //       headers: {
  //         "Content-Type": "application/json",
  //         Authorization: AuthToken,
  //       },
  //     });

  //     const responseData = await response.json();

  //     this.setState({
  //       userName: responseData.result.name,
  //       userImage: responseData.result.image,
  //     });
  //     localStorage.setItem("userimg", responseData.result.image);
  //   } catch (error) {
  //     console.error("Error fetching profile:", error);
  //   }
  // };
  logoutHandler = () => {
    localStorage.removeItem("userimg");
  };

  render() {
    const { userImage } = this.state;

    return (
      <Navbar expand="lg" className="bg-body-tertiary page-top-bar">
        <Container fluid>
          <Navbar.Toggle aria-controls="navbarScroll" />
          {/* <form className="header-search">
            <button type="submit">
              <i className="fa fa-search"></i>
            </button>
            <input
              type="text"
              placeholder="Search "
              name="search"
            />
          </form> */}
          <div className="accounts me-2">
                  <select
                    className="form-select"
                    name="TypeSelect"
                    id="TypeSelect"
                  >
                    <option>Account-1</option>
                    <option>Account-2</option>
                    <option>Account-3</option>
                  </select>
                </div>
          <Navbar.Collapse id="navbarScroll">
            <Nav
              className="me-auto my-2 my-lg-0"
              style={{ maxHeight: "100px" }}
              navbarScroll
            ></Nav>

            <Nav className="justify-content-end">
              <Nav.Link
                href="#action1"
                className="mx-4 custom-switch-container"
              ></Nav.Link>
              <div className="vr"></div>
              <Nav.Link href="#action2" className="ms-2 me-2 mt-1">
                <img src={BellIcon} alt="" />
              </Nav.Link>
              {/* <Nav.Link href="#" className="mx-4">
                <img src={GroupMemberIcon} alt="" />
              </Nav.Link> */}
              <div className="vr"></div>
              <NavDropdown
                className="ms-4"
                title={
                  <div>
                    <div className="profile-icon">
                      <div className="profile-img">
                        <div className="profile-icon d-flex align-items-center">
                         
                             <img src={ProfileIcon} alt="img" />
                             <span className="ms-2" style={{color:"#6b7280"}}>{this.state.userNames}</span>
                             
                             <span>
                        <img src={ProfileDropIcon} alt="drop icon" />
                      </span>
                        </div>
                        
                      </div>
                     
                      
                    </div>
                  </div>
                }
                id="navbarScrollingDropdown"
              >
                <NavDropdown.Item href="/my-account/profile">
                  <div
                    className="myProfileLogo"
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "10px",
                    }}
                  >
                    <i className="profile-logo" />
                    My Profile
                  </div>
                </NavDropdown.Item>
                <NavDropdown.Divider style={{ borderTop: "none" }} />

                {/* <NavDropdown.Item href="" className="upgrade-to-pro-text">
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "11px",
                    }}
                  >
                    <img
                      src={upgradetoproImage}
                      style={{ width: "25px" }}
                      alt="Upgrade to Pro"
                    />
                    Upgrade To PRO
                  </div>
                </NavDropdown.Item>
                <NavDropdown.Divider style={{ borderTop: "none" }} />

                <NavDropdown.Item href="/my-account/billing">
                  <div
                    className="bill"
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "10px",
                    }}
                  >
                    <i className="profileBilling-logo" />
                    Billing & Plan
                  </div>
                </NavDropdown.Item>
                <NavDropdown.Divider style={{ borderTop: "none" }} />
                <NavDropdown.Item href="/my-account/notification">
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "10px",
                    }}
                  >
                    {" "}
                    <i className="profileNotification-logo" />
                    Notification
                  </div>
                </NavDropdown.Item>
                <NavDropdown.Divider style={{ borderTop: "none" }} />
                <NavDropdown.Item href="/my-account/amazon-connection">
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "8px",
                    }}
                  >
                    <i className="amazonConnection-logo" />
                    Amazon Connection
                  </div>
                </NavDropdown.Item>
                <NavDropdown.Divider style={{ borderTop: "none" }} /> */}
                {/* <NavDropdown.Item href="" className="littleSpace">
                  <div>
                  <img
                      src={addaccount}
                      style={{ width: "20px" }}
                      alt="addaccount"
                  />
                  Add Account
                  </div>
                </NavDropdown.Item>
                <NavDropdown.Divider style={{ borderTop: "none" }} /> */}
                <NavDropdown.Item
                  href="/sign-in"
                  className="redColor"
                  onClick={this.logoutHandler}
                >
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "10px",
                    }}
                  >
                    <img
                      src={logouticon}
                      style={{ width: "40px", marginLeft: "-12px" }}
                      alt="addaccount"
                    />
                    Logout
                  </div>
                </NavDropdown.Item>
              </NavDropdown>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    );
  }
}

export default Header;

// import React from "react";
// import "./index.css";
// import OnOffIcon from "../../assets/images/icons/on-off-icon.svg";
// import BellIcon from "../../assets/images/icons/bell-icon.svg";
// import GroupMemberIcon from "../../assets/images/icons/group-member-icon.svg";
// import ProfileIcon from "../../assets/images/icons/profile-icon.svg";
// import ActiveIcon from "../../assets/images/icons/acive-green-icon.svg";
// import ProfileDropIcon from "../../assets/images/icons/profile-down-arrow.svg";
// import { Nav, Navbar, Container, NavDropdown } from "react-bootstrap";

// import Form from "react-bootstrap/Form";

// class Header extends React.Component {
//   state = {
//     userName: localStorage.getItem("userName"),
//     userImg: localStorage.getItem("userimg"),
//   };

//   render() {
//     return (
//       <Navbar expand="lg" className="bg-body-tertiary page-top-bar">
//         <Container fluid>
//           {/* <Navbar.Brand href="#">Navbar scroll</Navbar.Brand> */}
//           <Navbar.Toggle aria-controls="navbarScroll" />
//           {/* <Form className="d-flex">
//           <Form.Control
//             type="search"
//             placeholder="Search"
//             className="me-2"
//             aria-label="Search"
//           />
//         </Form> */}
//           <form className="header-search">
//             <button type="submit">
//               <i className="fa fa-search"></i>
//             </button>
//             <input
//               type="text"
//               placeholder="Search tutorial, post, community, etc..."
//               name="search"
//             />
//           </form>
//           <Navbar.Collapse id="navbarScroll">
//             <Nav
//               className="me-auto my-2 my-lg-0"
//               style={{ maxHeight: "100px" }}
//               navbarScroll
//             ></Nav>

//             <Nav className="justify-content-end">
//               <Nav.Link
//                 href="#action1"
//                 className="mx-4 custom-switch-container"
//               >
//                 {/* <Form>
//                 <Form.Check
//                   type="switch"
//                   id="custom-switch"
//                 />
//               </Form> */}
//               </Nav.Link>
//               <div className="vr"></div>
//               <Nav.Link href="#action2" className="ms-4">
//                 <img src={BellIcon} alt="" />
//               </Nav.Link>
//               <Nav.Link href="#" className="mx-4">
//                 <img src={GroupMemberIcon} alt="" />
//               </Nav.Link>
//               <div className="vr"></div>
//               <NavDropdown
//                 className="ms-4"
//                 title={
//                   <div>
//                     <div className="profile-icon">
//                       <div className="profile-img">
//                         <img
//                           src={this.state.userImg || ProfileIcon}
//                           alt="profile pic"
//                           style={{
//                             borderRadius: "50%",
//                             width: "30px",
//                             height: "30px",
//                             objectFit: "cover",
//                           }}
//                           loading="lazy"
//                           width={30}
//                           height={30}
//                         />

//                         <i>
//                           <img src={ActiveIcon} alt="acive icon" />
//                         </i>
//                       </div>
//                       {this.state.userName}
//                       <span>
//                         <img src={ProfileDropIcon} alt="drop icon" />
//                       </span>
//                     </div>
//                   </div>
//                 }
//                 id="navbarScrollingDropdown"
//               >
//                 <NavDropdown.Item href="/my-account/profile">
//                   Profile
//                 </NavDropdown.Item>
//                 <NavDropdown.Item href="/my-account/billing">
//                   Billing & Plan
//                 </NavDropdown.Item>
//                 <NavDropdown.Item href="/my-account/notification">
//                   Notifications
//                 </NavDropdown.Item>
//                 <NavDropdown.Divider />
//                 <NavDropdown.Item href="/sign-in">Logout</NavDropdown.Item>
//               </NavDropdown>
//             </Nav>
//           </Navbar.Collapse>
//         </Container>
//       </Navbar>
//     );
//   }
// }
// export default Header;

// import React, { Component } from "react";
// import "./index.css";
// import OnOffIcon from "../../assets/images/icons/on-off-icon.svg";
// import BellIcon from "../../assets/images/icons/bell-icon.svg";
// import GroupMemberIcon from "../../assets/images/icons/group-member-icon.svg";
// import ProfileIcon from "../../assets/images/icons/profile-icon.svg";
// import ActiveIcon from "../../assets/images/icons/acive-green-icon.svg";
// import ProfileDropIcon from "../../assets/images/icons/profile-down-arrow.svg";
// import { Nav, Navbar, Container, NavDropdown } from "react-bootstrap";

// class Header extends Component {
//   state = {
//     userNames: localStorage.getItem("userName"),
//     userImage: localStorage.getItem("userimg"),
//   };

//   componentDidMount() {
//     this.getProfile();
//   }

//   getProfile = async () => {
//     let userToken = localStorage.getItem("userToken");
//     let AuthToken = "Bearer " + userToken;

//     try {
//       const response = await fetch("https://api.aimosa.io/Users/Me", {
//         method: "GET",
//         headers: {
//           "Content-Type": "application/json",
//           Authorization: AuthToken,
//         },
//       });

//       const responseData = await response.json();

//       this.setState({
//         userName: responseData.result.name,
//         userImage: responseData.result.image,
//       });
//       localStorage.setItem("userimg", responseData.result.image);
//     } catch (error) {
//       console.error("Error fetching profile:", error);
//     }
//   };
//   logoutHandler = () => {
//     localStorage.removeItem("userimg");
//   };

//   render() {
//     const { userImage } = this.state;

//     return (
//       <Navbar expand="lg" className="bg-body-tertiary page-top-bar">
//         <Container fluid>
//           <Navbar.Toggle aria-controls="navbarScroll" />
//           <form className="header-search">
//             <button type="submit">
//               <i className="fa fa-search"></i>
//             </button>
//             <input
//               type="text"
//               placeholder="Search tutorial, post, community, etc..."
//               name="search"
//             />
//           </form>
//           <Navbar.Collapse id="navbarScroll">
//             <Nav
//               className="me-auto my-2 my-lg-0"
//               style={{ maxHeight: "100px" }}
//               navbarScroll
//             ></Nav>

//             <Nav className="justify-content-end">
//               <Nav.Link
//                 href="#action1"
//                 className="mx-4 custom-switch-container"
//               ></Nav.Link>
//               <div className="vr"></div>
//               <Nav.Link href="#action2" className="ms-4">
//                 <img src={BellIcon} alt="" />
//               </Nav.Link>
//               <Nav.Link href="#" className="mx-4">
//                 <img src={GroupMemberIcon} alt="" />
//               </Nav.Link>
//               <div className="vr"></div>
//               <NavDropdown
//                 className="ms-4"
//                 title={
//                   <div>
//                     <div className="profile-icon">
//                       <div className="profile-img">
//                         <div className="profile-icon d-flex align-items-center">
//                           {userImage ? (
//                             <img
//                               src={userImage}
//                               alt="profile pic"
//                               style={{
//                                 borderRadius: "50%",
//                                 width: "30px",
//                                 height: "30px",
//                                 objectFit: "cover",
//                               }}
//                               loading="lazy"
//                               width={30}
//                               height={30}
//                             />
//                           ) : (
//                             <div className="skeleton-loading"></div>
//                           )}
//                         </div>
//                         {userImage && (
//                           <i>
//                             <img src={ActiveIcon} alt="active icon" />
//                           </i>
//                         )}
//                       </div>
//                       {this.state.userNames}
//                       <span>
//                         <img src={ProfileDropIcon} alt="drop icon" />
//                       </span>
//                     </div>
//                   </div>
//                 }
//                 id="navbarScrollingDropdown"
//               >
//                 <NavDropdown.Item href="/my-account/profile">
//                   Profile
//                 </NavDropdown.Item>
//                 <NavDropdown.Item href="/my-account/billing">
//                   Billing & Plan
//                 </NavDropdown.Item>
//                 <NavDropdown.Item href="/my-account/notification">
//                   Notifications
//                 </NavDropdown.Item>
//                 <NavDropdown.Divider />
//                 <NavDropdown.Item href="/sign-in" onClick={this.logoutHandler}>
//                   Logout
//                 </NavDropdown.Item>
//               </NavDropdown>
//             </Nav>
//           </Navbar.Collapse>
//         </Container>
//       </Navbar>
//     );
//   }
// }

// export default Header;
