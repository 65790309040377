/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from "react";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import axios from "axios";
import "./index.css";
import { Row, Col } from "react-bootstrap";
import Nav from "react-bootstrap/Nav";
import Tab from "react-bootstrap/Tab";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import LogoIcon from "../../../assets/brand/logo-icon.svg";
import Moment from "moment";
import ColumnFilter from "../../../components/Filters/cloumn-filter";
import ConditionFilter from "../../../components/Filters/condition-filter";
import FilterIcon from "../../../assets/images/icons/filter-icon.svg";
import PauseIcon from "../../../assets/images/icons/pause.svg";
import ExportIcon from "../../../assets/images/icons/export.svg";
import { log } from "console";

let rows: any = [];
let columns: GridColDef[] = [];

function MarketplaceTable(props) {
  let apiEndPoint =
    "https://api.aimosa.io/Ads/CampaignManager/" + props.tabName;
  let pageload = true;
  let currPage = 1;
  let dataLength = 50;
  const [metaData, setMetaData] = useState<any>([]);
  const [apiLoading, setApiLoading] = useState(false);
  const [perPage, setPerPage] = useState(50);
  const [lastPage, setLastPage] = useState(1);
  const [activePage, setActivePage] = useState(1);
  const [totalRow, setTotalRow] = useState(0);
  const [filterDateRange, setFilterDateRange] = useState<any[]>([]);
  // const [dropdownDatas, setDropdownDatas] = useState<any[]>([]);
  // const [dropdownDataFilter, SetDropdownDataFilter] = useState<any[]>([]);
  // const [columFiltervalue, setColumFiltervalue] = useState<any[]>([]);
  const [data, setData] = useState<string[]>([]);

  // aaa
  const [searchKeyValue, setSearchKeyValue] = useState<any[]>([]);
  const [dropdownDatas, setDropdownDatas] = useState<any[]>([]);
  const [dropdownDataFilter, SetDropdownDataFilter] = useState<any[]>([]);
  const [columFiltervalue, setColumFiltervalue] = useState<any[]>([]);

  const [checked, setChecked] = useState(false);
  //const [dropdownDataFilter, setDropdownDataFilter] = useState([]);

  let filterData: any = [];
  const [globalFilterFromData, setGlobalFilterFromData] = useState<any[]>([]);
  const [searchKeyFilter, setSearchKeyFilter] = useState("");
  let searchKey = "";
  useEffect(() => {
    setMetaData(props.metaData);
  }, [props.metaData, metaData]);
  useEffect(() => {
    if (props.filterDateRange) {
      if (props.filterDateRange.length > 0) {
        if (filterDateRange !== props.filterDateRange) {
          setFilterDateRange(props.filterDateRange);
          pageload = true;
          fetchData();
          pageload = false;
        }
      }
    }
  });
  useEffect(() => {
    if (props.tabName && pageload) {
      fetchData();
      pageload = false;
    }
  }, []);

  const fetchData = async () => {
    setApiLoading(true);

    let userToken = localStorage.getItem("userToken");
    let AuthToken = "Bearer " + userToken;
    let url = apiEndPoint;
    let advancedFilters: any = [];
    let advancedFilterDateRange: any = {};

    if (filterData.length > 0) {
      advancedFilters = filterData;
    }
    if (props.filterDateRange.length > 0) {
      advancedFilterDateRange = {
        dateRange: "Custom",
        startDate:
          Moment(props.filterDateRange[0]).format("YYYY-MM-DD") +
          "T13:32:30.064Z",
        endDate:
          Moment(props.filterDateRange[1]).format("YYYY-MM-DD") +
          "T13:32:30.064Z",
      };
    }
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: AuthToken,
      },
      body: JSON.stringify({
        pageNumber: currPage,
        pageSize: dataLength,
        globalFilters: {
          searchText: searchKey,
          advancedFilters: advancedFilters,
          dateRanges: advancedFilterDateRange,
        },
      }),
    };
    try {
      const response = await fetch(url, requestOptions);
      const responceData = await response.json();
      let result = responceData.result;
      setTotalRow(result.total);
      setPerPage(result.perPage);
      setActivePage(result.currPage);
      setLastPage(result.lastPage);
      let headers = responceData.result.headers;

      setDropdownDatas(headers);
      SetDropdownDataFilter(headers);

      if (columns.length < 1) {
        for (let i = 0; headers.length > i; i++) {
          if (headers[i]["keyName"] === "campaignStatus") {
            columns.push({
              field: headers[i]["keyName"],
              headerName: headers[i]["displayName"],
              width: 80,

              renderCell: (params) => (
                <i className={"status " + params.row.campaignStatus}></i>
              ),
            });
          } else if (headers[i]["keyName"] === "marketplace") {
            columns.push({
              field: headers[i]["keyName"],
              headerName: headers[i]["displayName"],
              width: 80,

              renderCell: (params) => (
                <i className={"flag-" + params.row.marketplace}></i>
              ),
            });
          } else if (headers[i]["keyName"] === "campaign") {
            columns.push({
              field: headers[i]["keyName"],
              headerName: headers[i]["displayName"],
              minWidth: 250,
            });
          } else {
            columns.push({
              field: headers[i]["keyName"],
              headerName: headers[i]["displayName"],
              minWidth: 100,
            });
          }
        }
      }
      rows = responceData.result.data;
      setApiLoading(false);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const applyPagination = (e, pageNo: any) => {
    console.log("pagination no: ", pageNo);
    currPage = pageNo;
    fetchData();
  };

  const applyDataLength = (e) => {
    console.log("dataSize: ", e.target.value);
    dataLength = parseInt(e.target.value);
    fetchData();
  };

  const handleNvEnter = (event) => {
    console.log("Nv Enter:", event);
  };
  const handleCallback = (childData) => {
    setGlobalFilterFromData(childData);
    filterData = childData;
    fetchData();
  };
  const handleKeyDown = (event: any) => {
    if (event.key === "Enter") {
      searchKey = event.target.value;
      setSearchKeyFilter(event.target.value);
      fetchData();
      event.preventDefault();
    }
  };
  const cancelDropdown = () => {
    const eleRm: any = document.getElementsByClassName("dropdown-menu");
    for (let i = 0; i < eleRm.length; i++) {
      eleRm[i].classList.remove("show");
    }
  };
  // const hiddenColumns = columFiltervalue;

  // const applyColumns = () => {
  //   console.log("12345678" + searchKeyValue);
  //   setColumFiltervalue(searchKeyValue);

  //   // for (let i = 0; i < selectedPlan.length; i++) {
  //   //   if (selectedPlan[i].contains(eve.target)) {
  //   //     selectedPlan[i].classList.add("selected");
  //   //   }
  //   // }
  // };

  // aaa hide data
  const hiddenColumns = columFiltervalue;
  console.log("hide column : " + hiddenColumns);

  const applyColumns = () => {
    console.log("12345678 apply columns : " + searchKeyValue);
    // aaa
    setColumFiltervalue(searchKeyValue);
  };

  function openDropdown(e) {
    const eleRm: any = document.getElementById("dropdownCon2");
    eleRm.classList.remove("open");

    const ele: any = document.getElementById("dropdownCon");
    ele.classList.add("open");
  }
  function openDropdown2(e) {
    const eleRm: any = document.getElementById("dropdownCon");
    eleRm.classList.remove("open");

    const ele: any = document.getElementById("dropdownCon2");
    ele.classList.add("open");
  }

  const handleChange = (event, value: number) => {
    currPage = value;
    fetchData();
  };
  // const filterdData = (e) => {
  //   console.log(e.target.value);
  //   setColumFiltervalue(e.target.value);
  // };
  // function onItemCheck(e, item) {
  //   if (e.target.name == searchKeyValue) {
  //     setSearchKeyValue((current) =>
  //       current.filter((searchKeyValue) => {
  //         return searchKeyValue !== e.target.name;
  //       })
  //     );
  //   } else {
  //     console.log(e.target.checked + e.target.name);
  //     setSearchKeyValue((current) => [current, e.target.name]);
  //     // console.log(item);
  //     setData([]);
  //   }
  // }

  // filter
  const filterdData = (e) => {
    console.log("click check button " + e.target.value);
    // aaa
    setColumFiltervalue(e.target.value);
  };

  // master check
  // function onMasterCheck(e) {
  //   setChecked(e.target.checked);

  //   // Update the checked state for all items in the list
  //   SetDropdownDataFilter((prevData) =>
  //     prevData.map((item) => ({
  //       ...item,
  //       checked: e.target.checked,
  //     }))
  //   );
  //   // Update the searchKeyValue based on the master checkbox
  //   if (e.target.checked) {
  //     const allKeys = dropdownDataFilter.map((item) => item.keyName);
  //     setSearchKeyValue(allKeys);
  //   } else {
  //     setSearchKeyValue([]);
  //   }
  // }

  // set condiction
  function onItemCheck(e, item) {
    if (e.target.checked === true) {
      setSearchKeyValue((current) => [...current, e.target.name]);
      console.log("setSearchKeyValue true " + setSearchKeyValue);
    }

    if (e.target.checked === false) {
      setSearchKeyValue((current) =>
        current.filter((item) => item !== e.target.name)
      );
      console.log("setSearchKeyValue false " + setSearchKeyValue);
    }
  }

  //

  let userToken = localStorage.getItem("userToken");
  let AuthToken = "Bearer " + userToken;

  const [csvData, setCsvData] = useState(null);

  const handleExportData = async () => {
    try {
      const response = await fetch(
        "https://api.aimosa.io/Ads/CampaignManager/Marketplaces/Export",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: AuthToken,
          },
          body: JSON.stringify({}),
        }
      );

      if (!response.ok) {
        throw new Error("Failed to export data");
      }

      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.style.display = "none";
      a.href = url;
      a.download = "Marketplace exported_data.csv";
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);

      // Handle success
      console.log("Export successful");
    } catch (error) {
      console.error("Error exporting data:", error);
    }
  };

  return (
    <div style={{ height: 500, width: "100%" }}>
      <Row className="mt-2 mb-2">
        <Col md={5}>
          {props.checkBox && (
            <div className="bulk-operations">
              <div className="cus-dropdown">
                <span>Bulk operation</span>
                <i
                  className="fa fa-angle-down down-arrow-right"
                  aria-hidden="true"
                ></i>
                <div className="dropdown-container">
                  <form>
                    <Tab.Container
                      id="left-tabs-example"
                      defaultActiveKey="first"
                    >
                      <Row>
                        <Col sm={4}>
                          <Nav variant="pills" className="flex-column">
                            <Nav.Item>
                              <Nav.Link eventKey="first">Status</Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                              <Nav.Link eventKey="second">
                                Daily Budget
                              </Nav.Link>
                            </Nav.Item>
                          </Nav>
                        </Col>
                        <Col sm={8}>
                          <Tab.Content>
                            <Tab.Pane eventKey="first">
                              <select
                                className="form-select"
                                id="bulkaction-status"
                              >
                                <option selected>Status</option>
                                <option value="Enabled">Enabled</option>
                                <option value="Paused">Paused</option>
                                <option value="Archived">Archived</option>
                              </select>
                            </Tab.Pane>
                            <Tab.Pane eventKey="second">
                              <div>
                                <label>Daily Budget</label>
                                <select
                                  className="form-select"
                                  id="bulkaction-daily-budget"
                                >
                                  <option selected>Select</option>
                                  <option value="Increase daily budget by">
                                    Increase daily budget by
                                  </option>
                                  <option value="Lower daily budget by">
                                    Lower daily budget by
                                  </option>
                                  <option value="Set daily budget to">
                                    Set daily budget to
                                  </option>
                                </select>
                              </div>
                              <div>
                                <label>Daily Budget Value</label>
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="Enter Value"
                                  aria-label="value"
                                  aria-describedby="addon-wrapping"
                                />
                              </div>
                              <div>
                                <label>Adjust bid value options</label>
                                <select
                                  className="form-select"
                                  id="bulkaction-value-options"
                                >
                                  <option selected>Select</option>
                                  <option value="%">%</option>
                                  <option value="$">$</option>
                                </select>
                              </div>
                            </Tab.Pane>
                          </Tab.Content>
                        </Col>
                      </Row>
                    </Tab.Container>
                    <hr />
                    <div className="footer">
                      <button type="button" className="btn btn-default">
                        Cancel
                      </button>
                      <button
                        type="button"
                        className="btn btn-primary"
                        disabled
                      >
                        Apply
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          )}
        </Col>
        <Col>
          <div className="filter-container">
            <Row>
              <Col md={3} className="padding-lr-10"></Col>
              <Col md={9}>
                <div>
                  <form>
                    <div className="search-filter-container">
                      <i className="fa fa-search"></i>
                      <input
                        type="text"
                        placeholder="Search"
                        id="globalSearch"
                        name="globalSearch"
                        onKeyDown={handleKeyDown}
                      />
                    </div>
                  </form>
                </div>
                <div className="filter-item filter-link-container dropdownContent">
                  <p
                    id="dropdownMenuButton1"
                    data-bs-toggle="dropdown"
                    data-bs-auto-close="outside"
                    aria-expanded="false"
                    data-bs-display="static"
                  >
                    <i>
                      <img src={FilterIcon} alt="filter icon" />
                    </i>
                    <span>Filter</span>
                    <i
                      className="fa fa-angle-down down-arrow-right"
                      aria-hidden="true"
                    ></i>
                  </p>
                  <div
                    className="dropdown-menu dropdown-menu-lg-end"
                    aria-labelledby="dropdownMenuButton1"
                  >
                    <ConditionFilter
                      parentCallback={handleCallback}
                      dropdownData={dropdownDatas}
                      metaData={metaData}
                    />
                  </div>
                </div>

                {/* <div className="filter-item column-link-container dropdownContent">
                  <p
                    id="dropdownMenuButton2"
                    data-bs-toggle="dropdown"
                    data-bs-auto-close="outside"
                    aria-expanded="false"
                    data-bs-display="static"
                  >
                    <i>
                      <img src={PauseIcon} alt="filter icon" />
                    </i>
                    <span>Columns</span>
                    <i
                      className="fa fa-angle-down down-arrow-right"
                      aria-hidden="true"
                    ></i>
                  </p>
                  <div
                    className="dropdown-menu dropdown-menu-lg-start"
                    aria-labelledby="dropdownMenuButton1"
                  >
                    <form>
                      <div className="set-max-height-400">
                        <table className="table">
                          <thead>
                            <tr>
                              <th scope="col">
                            
                                <input
                                  type="checkbox"
                                  className="form-check-input"
                                  
                                  id="mastercheck"
                                  
                                />
                              </th>
                              <th scope="col">Column Name</th>
                            </tr>
                          </thead>
                          <tbody>
                            {dropdownDataFilter.map((list, i) => (
                              <tr
                                key={list.keyName}
                                className={list.selected ? "selected" : ""}
                              >
                                <th scope="row">
                                  <input
                                    type="checkbox"
                                    checked={list.checked}
                                    className="form-check-input Selected-filter"
                                    
                                    onChange={(e) =>
                                      onItemCheck(e, list.selected)
                                    }
                                    name={list.keyName}
                                  />
                                </th>
                                <td>{list.displayName}</td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                     
                      </div>
                      <hr />
                      <div className="footer">
                        <button
                          type="button"
                          className="btn btn-default"
                          onClick={cancelDropdown}
                        >
                          Cancel
                        </button>
                        <button
                          type="button"
                          className="btn btn-primary"
                         
                          onClick={applyColumns}
                        >
                          Apply
                        </button>
                      </div>
                    </form>
                  </div>
                </div> */}
                <div className="filter-item export-link-container">
                  {/* <p>
                    <i>
                      <img src={ExportIcon} alt="filter icon" />
                    </i>
                    <span>Export</span>
                  </p> */}
                  <p>
                    <i>
                      <img src={ExportIcon} alt="filter icon" />
                    </i>
                    {/* <button className="export-btn" onClick={handleExportData}>
                      Export{" "}
                    </button> */}
                    <span onClick={handleExportData}>Export</span>
                  </p>
                </div>
              </Col>
            </Row>
          </div>
        </Col>
      </Row>
      {!apiLoading ? (
        <div style={{ height: 400, width: "100%" }}>
          <DataGrid
            rows={rows}
            // columns={columns}
            // aaa
            columns={columns.filter(
              (col) => !hiddenColumns.includes(col.field)
            )}
            checkboxSelection={props.checkBox}
            hideFooter={true}
            rowHeight={40}
          />
          <div className="custom-table-footer">
            <Row>
              <Col md={5}>
                <form className="table-footer-left">
                  <span>Show </span>
                  <label>
                    <select
                      className="form-select"
                      defaultValue={perPage}
                      onChange={(event) => applyDataLength(event)}
                    >
                      <option value="50">50</option>
                      <option value="100">100</option>
                      <option value="150">150</option>
                      <option value="200">200</option>
                    </select>
                  </label>
                  <span> of {totalRow} total entries</span>
                </form>
              </Col>
              <Col md={7}>
                <div className="table-footer-right">
                  <Stack spacing={2}>
                    <Pagination
                      count={lastPage}
                      page={activePage}
                      variant="outlined"
                      shape="rounded"
                      onChange={handleChange}
                    />
                  </Stack>
                </div>
              </Col>
            </Row>
          </div>
        </div>
      ) : (
        <div className="loading-container">
          <div className="loading-text">
            <span className="logicon">
              {/* <img src={LogoIcon} alt="logo-small"></img> */}
            </span>
            <span>L</span>
            <span>O</span>
            <span>A</span>
            <span>D</span>
            <span>I</span>
            <span>N</span>
            <span>G</span>
          </div>
        </div>
      )}
    </div>
  );
}
export default MarketplaceTable;
